<template>
    <vue-table-card title="Transactions" :fields="fields" :url="listUrl" :per-page="10" ref="table">
        <template #actions="{rowData}">
            <div class="btn-group">
                <btn :loading="loading && itemsStatusUpdateOnProgress.includes(rowData.id)"
                     :disabled="loading && itemsStatusUpdateOnProgress.includes(rowData.id)"
                     loading-text="processing.." size="sm" title="Update Status"
                     @click="updateTrnStatus(rowData.merch_txn_id, rowData.id)"/>
                <btn :disabled="loading && itemsStatusUpdateOnProgress.includes(rowData.id)"
                     size="sm" title="Details" @click="viewDetails(rowData)"/>
            </div>
        </template>
        <template #txn_date="{rowData}">
            {{ formatDate(rowData.txn_date) }}
        </template>
    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'Transactions',
    data () {
        return {
            loading: false,
            itemsStatusUpdateOnProgress: [],
            updatingPayment: false,
            user_type: '',
            payment_status: '',
            statusOptions: [
                {
                    label: 'Transaction Initiated',
                    value: 'Transaction Initiated'
                },
                {
                    label: 'Transaction Processing',
                    value: 'Transaction Processing'
                },
                {
                    label: 'Transaction Completed',
                    value: 'Transaction Completed'
                },
                {
                    label: 'Transaction Failed',
                    value: 'Transaction Failed'
                }
            ],
            userTypeOptions: [
                {
                    label: 'Individual',
                    value: 'Individual'
                },
                {
                    label: 'Organization',
                    value: 'Organization'
                }
            ],
            listUrl: urls.admin.payment.transactionList,
            fields: [
                {
                    name: 'course_register_id.reference_id',
                    sortField: 'course_register_id.reference_id',
                    title: 'Reference ID'
                },
                {
                    name: 'course_fee',
                    sortField: 'course_fee',
                    title: 'Course Fee'
                },
                {
                    name: 'bank_trn_id',
                    sortField: 'bank_trn_id',
                    title: 'Bank Txn ID'
                },
                {
                    name: 'status_description',
                    dataClass: 'w-20r',
                    sortField: 'status_description',
                    title: 'Status Description'
                },
                {
                    name: '__slot:txn_date',
                    title: 'Txn Date'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        formatDate (dateStr) {
            const parsedDate = new Date(dateStr);
            if (isNaN(parsedDate)) {
                return dateStr || '';
            }
            const year = parsedDate.getFullYear();
            const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
            const day = String(parsedDate.getDate()).padStart(2, '0');
            const hours = String(parsedDate.getHours()).padStart(2, '0');
            const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
            const seconds = String(parsedDate.getSeconds()).padStart(2, '0');
            return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
        },
        viewDetails (item) {
            this.$router.push('/admin/course-register/' + item.course_register_id.id + '/details/');
        },
        async updateTrnStatus (merchTxnId, id) {
            if (!this.itemsStatusUpdateOnProgress.includes(id)) {
                this.itemsStatusUpdateOnProgress.push(id);
            }
            this.loading = true;
            const response = await axios.form(urls.admin.payment.checkStatus, { merch_txn_id: merchTxnId });
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Sorry, Permission denied to the request', 'Permission Denied', {
                    type: 'danger'
                });
            } else {
                if (json.error === false) {
                    this.$refs.table.refreshTable();
                    if (json.data.description) {
                        this.$notify('Status : ' + json.data.description, 'Status Update', {
                            type: 'success'
                        });
                    } else {
                        this.$notify('Status : Transaction Pending', 'Status Update', {
                            type: 'success'
                        });
                    }
                } else {
                    this.$notify('Could not update the status, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            const index = this.itemsStatusUpdateOnProgress.indexOf(id);
            if (index !== -1) {
                this.itemsStatusUpdateOnProgress.splice(index, 1);
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
